<template>
  <div class="uploadWrap">
    <el-upload
      ref="upload"
      :auto-upload="false"
      :on-change="handleChange"
      :limit="1"
      accept=".jpg, .jpeg, .png, .JPG, .JPEG"
      action="action"
      name="file"
      class="uploadchoose"
    >
      <div class="prevWrap">
        <div>{{ hasName === 1 ? name : item.imgName }}</div>
        <div class="priv">预览</div>
      </div>
    </el-upload>
    <!-- <el-button class="del pointer" type="primary" @click="del">清空图片</el-button> -->
  </div>
</template>
<script>
import { CONFIG } from "@/utils/config.js";
export default {
  props: {
    fileList: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    filePath: {
      type: String,
      default: "",
    },
    hasName: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      action: CONFIG + "banner",
    };
  },
  methods: {
    // 上传状态变化
    handleChange(file) {
      if (file.status === "success" || file.status === "fail") {
        this.$emit("update:fileList", {});
      } else {
        // this.fileList = file
        // //console.log(file)
        if (file.size / 1024 / 1024 > 2) {
          this.$message1("上传图片的大小不能超过2M", "warning");
          this.$refs.upload.clearFiles();
          return;
        }
        var data = window.URL.createObjectURL(new Blob([file.raw]));
        this.$emit("chooseImage", {
          img: data,
          fileList: file,
          item: this.item,
          uploadtype: this.uploadtype,
          filePath: this.filePath,
        });
        this.$refs.upload.clearFiles();
      }
    },
    // 取消dialog
    cancelDialog() {
      this.$emit("update:fileList", {});
      this.$refs.upload.clearFiles();
    },
    // del() {
    //   this.$emit('delImg', { item: this.item, uploadtype: this.uploadtype })
    // }
  },
};
</script>
<style lang="scss">
.uploadWrap {
  display: flex;
  margin-top: 10px;
  .uploadchoose {
    flex: 1;
  }
  .el-upload {
    width: 100%;
  }
  // margin-top:10px;
  .prevWrap {
    display: flex;
    width: 440px;
    box-sizing: border-box;
    border: solid 1px #e5e5e5;
    align-items: center;
    height: 40px;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #808080;
    }
    > div:nth-of-type(1) {
      flex: 1;
      padding: 0 10px 0;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > div:nth-of-type(2) {
      width: 110px;
      background-color: #f2f2f2;
      justify-content: center;
      border-left: solid 1px #e5e5e5;
      cursor: pointer;
    }
    .del {
      margin-left: 3px;
    }
  }
}
</style>
