<template>
  <div :style="showCropper2 ? 'overflow:hidden;' : ''" class="detailContainer">
    <div v-if="showCropper2" class="mark" />
    <div class="titleCommon" style="margin-top: 40px">
      {{ $route.meta.title }}
    </div>
    <el-form
      ref="signForm"
      :model="signForm"
      :rules="signRules"
      @submit.native.prevent
    >
      <div class="wrap te">
        <div class="leftBorder color1">
          <div class="top-yuan yuan-color1">1</div>
          <div class="bottom-yuan yuan1" />
        </div>
        <p class="title">
          活动标题
          <span>（必填）</span>
        </p>
        <el-form-item class="elItem" prop="title">
          <el-input v-model.trim="signForm.title" class="input" />
        </el-form-item>
        <p class="title">
          添加封面图片
          <span>（必须添加并且图片大小不能超过2M）</span>
        </p>
        <upload
          ref="uploadWrap"
          :file-list="fileList"
          :file-path="'news/1/JpegQ75W750'"
          :has-name="1"
          @chooseImage="chooseImage"
        />

        <p class="title titleSub">封面的图片大小为750*432像素</p>
        <div class="bigImgWrap">
          <div v-if="!signForm.imageUrl">待上传</div>
          <img v-if="signForm.imageUrl" :src="signForm.imageUrl" alt />
        </div>
        <p class="title">发布时间</p>
        <el-date-picker
          v-model="signForm.createTime"
          type="datetime"
          placeholder="选择日期时间"
          style="margin-top: 10px"
        />
        <p class="title">活动时间 <span>（必填）</span></p>
        <el-form-item class="elItem" prop="activityEndTime">
          <TimeSelect :time="eventTime" @change="changeTime" />
        </el-form-item>
        <p class="title">活动表单设置(选中后表单内容会出现在报名框中)</p>
        <div class="checkWrap">
          <el-checkbox checked label="姓名" border disabled />
          <el-checkbox checked label="手机" border disabled />
          <el-checkbox
            v-model="signForm.companyFlag"
            :true-label="trueLabel"
            :false-label="falseLabel"
            label="公司"
            border
          />
          <el-checkbox
            v-model="signForm.numberFlag"
            :true-label="trueLabel"
            :false-label="falseLabel"
            label="人数"
            border
          />
          <el-checkbox
            v-model="signForm.positionFlag"
            :true-label="trueLabel"
            :false-label="falseLabel"
            label="职位"
            border
          />
          <el-checkbox
            v-model="signForm.questionFlag"
            :true-label="trueLabel"
            :false-label="falseLabel"
            label="提问"
            border
          />
        </div>
        <p class="title">
          活动正文
          <span>（必填）</span>
        </p>
        <div style="padding-top: 20px; padding-bottom: 20px">
          <Tinymce v-model="signForm.data" :height="700" />
          <!-- <div class="editor-content">{{ content }}</div> -->
        </div>
        <div class="downBorder" />
      </div>
      <div class="wrap te2">
        <div class="wrap-content">
          <div class="left-flex">
            <div class="leftBorder color2">
              <div class="top-yuan yuan-color2">2</div>
              <div class="bottom-yuan yuan2" />
            </div>
            <p class="bigtitle">签到参会证与资料下载</p>
            <p class="title">
              会议标题
              <span>（必填）</span>
            </p>
            <el-form-item class="elItem" prop="activityTitle">
              <el-input
                v-model="signForm.activityTitle"
                style="width: 440px"
                placeholder="请输入标题，字数不超过22"
                maxlength="22"
                show-word-limit
                class="input"
              />
            </el-form-item>
            <p class="title" style="padding-bottom: 10px">添加会议文件</p>
            <div
              class="contentWrap"
              style="overflow-y: scroll; height: 400px; margin-bottom: 5px"
            >
              <upPdf
                :pdf-list.sync="signForm.parkPdf"
                :limit="30"
                :multiple="true"
                :path="'pdf/2/null'"
              />
            </div>
          </div>
          <div class="right-flex">
            <div class="rightContent">
              <p>签到证样式</p>
              <div class="certificate">
                <img
                  src="https://cdn.joinzs.com/mpgenerator/program/defaulticon/icon/1597129801586.png?x-oss-process=image/auto-orient,1/resize,m_lfit,w_750/quality,Q_75/format,png"
                  alt=""
                />
                <div class="certificate-title">
                  {{ signForm.activityTitle }}
                </div>
                <div class="name">姓名</div>
                <div v-if="signForm.positionFlag" class="position">职位</div>
                <div class="phone">手机号码</div>
                <div v-if="signForm.companyFlag" class="address">
                  所在园区、单位、或企业名称
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="downBorder bor-te" />
      </div>
      <div class="wrap te3">
        <div class="leftBorder color3">
          <div class="top-yuan">3</div>
          <div class="bottom-yuan yuan3" />
        </div>
        <p class="bigtitle">线下签到二维码</p>
        <div style="display: flex">
          <div class="left-erweima">
            <img
              :src="
                signForm.newsId
                  ? erweima
                  : 'https://cdn.joinzs.com/mpgenerator/program/defaulticon/icon/1597130697270.png?x-oss-process=image/auto-orient,1/resize,m_lfit,w_750/quality,Q_75/format,png'
              "
              class="img-er"
            />
          </div>
          <div class="right-down">
            <p>活动内容保存后可下载二维码</p>
            <div
              :class="'xiazai' + (signForm.newsId ? ' liang' : '')"
              @click="handleDown"
            >
              下载
            </div>
          </div>
        </div>
        <div class="downBorder bor-te3" />
      </div>
      <div class="btnWrap">
        <el-button :loading="signLoading" round @click="submit"
          >保存上传</el-button
        >
      </div>
    </el-form>
    <Cropper
      :show.sync="showCropper2"
      :cropper-img-url="cropperImgUrl"
      :file-list.sync="fileList"
      :fixed-number="fixedNumber"
      :path="filePath"
      @cubeImg="cubeImg"
      @cancelCropper="cancelCropper"
    />
  </div>
</template>
<script>
import EventBus from "@/utils/eventBus";
import Tinymce from "@/components/Tinymce";
import {
  getActivityDetail,
  saveOneActivity,
  downActivityImg,
} from "@/apis/user";
import TimeSelect from "@/components/timeSelect";
import Cropper from "@/components/cropper";
import Upload from "@/components/upload";
import upPdf from "@/components/uploadPdf/index";

export default {
  components: { Tinymce, Cropper, Upload, TimeSelect, upPdf },
  data() {
    const validateTitle = (rule, value, callback) => {
      // //console.log(value)
      if (this.signForm.title.trim() === "") {
        callback(new Error("请输入新闻标题"));
      } else {
        callback();
      }
    };
    const validateTime = (rule, value, callback) => {
      if (!value || value.length === 0) {
        callback(new Error("请选择活动时间"));
      } else {
        callback();
      }
    };
    const validateactivityTitle = (rule, value, callback) => {
      if (this.signForm.activityTitle.trim() === "") {
        callback(new Error("请输入会议标题"));
      } else {
        callback();
      }
    };
    return {
      signForm: {
        // 表单form
        title: "", // 标题
        data: "", // 新闻内容
        imageUrl: "", // 封面如偏
        activityStartTime: "",
        activityEndTime: "",
        companyFlag: 0,
        positionFlag: 0,
        numberFlag: 0,
        questionFlag: 0,
        createTime: "",
        activityTitle: "",
        parkPdf: [],
        newsId: "",
      },
      activityFilesId: [],
      eventTime: [],
      trueLabel: 1,
      falseLabel: 0,
      signLoading: false, // 保存loading
      signRules: {
        // 表单验证规则
        title: [
          {
            required: true,
            trigger: "blur",
            validator: validateTitle,
          },
        ],
        activityEndTime: [
          {
            required: true,
            trigger: "submit",
            validator: validateTime,
          },
        ],
        activityTitle: [
          {
            required: true,
            trigger: "blur",
            validator: validateactivityTitle,
          },
        ],
      },
      fileList: {}, // 预览的信息
      showCropper2: false,
      cropperImgUrl: "",
      fixedNumber: [1.74, 1],
      filePath: "",
      erweima: "",
    };
  },
  mounted() {
    const { newsId } = this.$route.params;
    this.signForm.newsId = newsId;
    if (newsId) {
      getActivityDetail({ newsId }).then((res) => {
        this.signForm = res.result;
        this.signForm.parkPdf = res.result.files.map((item) => {
          const newItem = { ...item, name: item.filename, url: item.url };
          delete newItem.filename;
          return newItem;
        });
        this.signForm.createTime = new Date(res.result.createTime);
        this.eventTime = [
          new Date(res.result.activityStartTime),
          new Date(res.result.activityEndTime),
        ];
      });
      this.handleQrcode();
    }
  },
  methods: {
    cancelCropper(e) {
      this.showCropper2 = e;
      this.fileList = {};
    },
    // 选择图片事件的反馈
    chooseImage(e) {
      // //console.log(e)
      this.showCropper2 = true;
      this.cropperImgUrl = e.img;
      this.fileList = e.fileList;
      this.filePath = e.filePath;
    },
    cubeImg(e) {
      this.showCropper2 = false;
      this.signForm.imageUrl = e.imgNew;
      this.signForm.imageId = e.id;
      this.fileList = {};
    },
    changeTime(e) {
      this.eventTime = e;

      this.signForm.activityStartTime = e.length === 2 ? e[0] : "";
      this.signForm.activityEndTime = e.length === 2 ? e[1] : "";
    },
    submit() {
      var activityFilesList = [];
      this.signForm.parkPdf.forEach((item) => {
        if (item.id) {
          activityFilesList.push(item.id);
        } else {
          activityFilesList.push(item.response.id);
        }
      });
      this.$refs.signForm.validate((valid) => {
        // 表单验证
        if (valid) {
          if (this.signForm.imageUrl === "") {
            this.$message1("请选择封面图片", "warning");
            return;
          }
          if (this.signForm.data === "") {
            this.$message1("请填写活动正文", "warning");
            return;
          }
          var message = "";
          if (this.signForm.newsId) {
            message = "修改";
          } else {
            message = "新增";
          }
          this.$handleConfirm("请确认是否要" + message + "这条活动?")
            .then(() => {
              this.signLoading = true;
              this.signForm.createTime = new Date(
                this.signForm.createTime
              ).getTime();
              this.signForm.activityFilesId = activityFilesList;
              delete this.signForm.files;
              delete this.signForm.pdfList;
              saveOneActivity(this.signForm)
                .then(() => {
                  if (this.signForm.newsId) {
                    this.$message1("修改成功", "success");
                  } else {
                    this.$message1("新增成功", "success");
                    EventBus.$emit("needRefrush", true);
                  }

                  this.$router.back();
                  this.signLoading = false;
                })
                .catch(() => {
                  this.signLoading = false;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (this.signForm.title.trim() === "") {
            this.$message1("请填写活动标题", "warning");
            return;
          } else if (this.signForm.activityTitle.trim() === "") {
            this.$message1("请填写会议标题", "warning");
            return;
          }
        }
      });
    },
    handleDown() {
      const that = this;
      if (this.signForm.newsId) {
        var qrData = {
          page: "package/signin/register/register",
          scene: this.$route.params.newsId,
          width: 280,
        };
        downActivityImg(qrData).then((res) => {
          var x = new XMLHttpRequest();
          x.open("GET", res.result.image.split("?")[0], true);
          x.responseType = "blob";
          x.onload = function () {
            var url = window.URL.createObjectURL(x.response);
            var a = document.createElement("a");
            a.href = url;
            a.download = that.signForm.title + "活动签到太阳码";
            a.click();
          };
          x.send();
        });
      } else {
        this.$message1("保存活动后，即可下载", "warning");
      }
    },
    handleQrcode() {
      const that = this;
      if (this.signForm.newsId) {
        var qrData = {
          page: "package/signin/register/register",
          scene: this.$route.params.newsId,
          width: 280,
        };
        downActivityImg(qrData).then((res) => {
          that.erweima = res.result.image;
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../styles/mixin";
.detailContainer {
  .elItem {
    margin: 10px 0 0 0;
  }
  .input {
    max-width: 750px;
  }
  .typeSelect {
    //类型class
    width: 128px;
  }
  .el-form {
    margin: 32px 60px 0 190px;
    display: flex;
    flex-direction: column;
  }
  .el-form-item__content {
    display: flex;
  }
  .el-input {
    display: flex;
    align-items: center;
    input {
      -webkit-appearance: none;
      color: #333;
      caret-color: #333;
      border: 1px solid #e5e5e5;
      font-size: 16px;
      line-height: 20px;
      height: 40px;
    }
  }
  .textarea {
    margin-top: 18px;
  }
  .el-textarea__inner {
    max-width: 750px;
    border: solid 1px #cccccc;
    min-height: 112px !important;
  }
  .uploadWrap {
    display: flex;
    margin-top: 10px;
    max-width: 750px;
    .uploadchoose {
      width: 100%;
      max-width: 440px;
    }
    .el-upload {
      width: 100%;
    }
    .prevWrap {
      display: flex;
      // width:440px;
      max-width: 440px;
      box-sizing: border-box;
      border: solid 1px #e5e5e5;
      align-items: center;
      height: 40px;
      > div {
        height: 100%;

        display: flex;
        align-items: center;
        font-size: 16px;
        color: #808080;
      }
      > div:nth-of-type(1) {
        padding: 0 30px 0 16px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > div:nth-of-type(2) {
        width: 110px;
        background-color: #f2f2f2;
        justify-content: center;
        border-left: solid 1px #e5e5e5;
        cursor: pointer;
      }
    }
  }
  .bigImgWrap {
    margin-top: 22px;
    div {
      width: 186px;
      height: 106px;
      border-radius: 12px;
      border: dashed 1px #b2b2b2;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      font-size: 16px;
    }
    img {
      width: 186px;
      height: 106px;
      border-radius: 12px;
    }
  }
  .btnWrap {
    overflow: hidden;
    max-width: 750px;
    padding-bottom: 50px;
    margin-top: 20px;
    .el-button {
      float: right;
      @include btnCardConfirm(116px, 40px, 16px);
    }
  }
  .upload,
  .confirm {
    margin-left: 10px;
    @include btnCardConfirm(116px, 40px, 16px);
  }
}
@media only screen and (max-width: 470px) {
}
</style>

<style lang="scss" scoped>
.detailContainer {
  // padding-top: 40px;
  // padding-bottom: 50px;
  // box-sizing: border-box;
  height: calc(100vh - 118px);
  .titleCommon {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
  }
  .wrap {
    position: relative;
    margin-top: 30px;
  }
  .te {
    padding-top: 0;
  }
  .te2 {
    // padding-bottom: 375px;
    display: flex;
    flex-direction: column;
  }
  .wrap-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .bigtitle {
    line-height: 1;
    color: #333;
    font-size: 23px;
    margin-bottom: 50px;
  }
  .img-er {
    width: 181px;
  }
  .rightContent {
    height: 614px;
  }
  .rightContent p {
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(26, 26, 26, 1);
  }
  .rightContent img {
    width: 334px;
    height: 614px;
  }
  .certificate {
    position: relative;
  }
  .certificate-title {
    position: absolute;
    top: 131px;
    left: 50%;
    transform: translateX(-50%);
    // height:15px;
    // line-height: 1;
    font-size: 15px;
    font-weight: 500;

    text-align: center;
    width: 167px;
    color: rgba(26, 26, 26, 1);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .certificate .name {
    position: absolute;
    height: 19px;
    left: 152px;
    top: 273px;
    line-height: 1;
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
  .certificate .position {
    position: absolute;
    height: 9px;
    line-height: 1;
    top: 300px;
    left: 160px;
    font-size: 10px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .certificate .phone {
    position: absolute;
    height: 15px;
    line-height: 15px;
    top: 320px;
    left: 143px;
    font-size: 15px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
  .certificate .address {
    position: absolute;
    height: 10px;
    line-height: 1;
    top: 341px;
    left: 90px;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .left-erweima {
    width: 181px;
    left: 0;
  }
  .right-down {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 65px;
  }
  .right-down p {
    margin-top: 80px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
  }
  .xiazai {
    width: 116px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba(179, 179, 179, 0.96);
    border-radius: 9px;
    color: #fff;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
  }
  .liang {
    background-color: #409eff;
  }
  .leftBorder {
    position: absolute;
    left: -39px;
    height: 100%;
    width: 24px;
  }
  .wrap.te3 {
    padding-bottom: 20px;
  }
  .color1 {
    background-color: #d1f1ff;
  }
  .color2 {
    background-color: #c3ffe8;
  }
  .color3 {
    background-color: #ffeaca;
  }
  .wrap .leftBorder .top-yuan {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);

    background: rgba(244, 152, 8, 1);
    border-radius: 50%;
  }
  .wrap .leftBorder .yuan-color1 {
    background-color: #00b3ff;
  }
  .wrap .leftBorder .yuan-color2 {
    background-color: #3fdb9e;
  }
  .wrap .leftBorder .bottom-yuan {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50%;
  }
  .yuan1 {
    background-color: #d1f1ff;
  }
  .yuan2 {
    background-color: #c3ffe8;
  }
  .yuan3 {
    background-color: #ffeaca;
  }
  .downBorder {
    position: relative;
    bottom: 0;
    overflow: hidden;
    max-width: 750px;
    height: 1px;
    background: rgba(179, 179, 179, 1);
  }
  .downBorder.bor-te3 {
    bottom: -20px;
  }
  .title {
    line-height: 1;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
    span {
      color: #808080;
    }
  }
  .titleSub {
    color: #b3b3b3;
    margin-top: 10px;
  }
  .checkWrap {
    margin-top: 10px;
  }
}
</style>
