<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:03:01
-->
<template>
  <el-date-picker
    v-model="value3"
    type="datetimerange"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    @change="timeChange"
  />
</template>
<script>
export default {
  // model: {
  //   prop: 'time', // prop说:我要在该组件被使用(被父组件调用)时,v-model的值绑定给value1
  //   event: 'change' // event说:我emit(触发)change的时候，参数的值就是父组件v-model收到的值。
  // },
  props: {
    time: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value3: "",
    };
  },
  watch: {
    time(newValue) {
      this.value3 = newValue;
    },
  },

  methods: {
    timeChange(e) {
      var arr = [];
      if (e && e.length > 0) {
        e.forEach((item) => {
          arr.push(new Date(item).getTime());
        });
      }
      this.$emit("change", arr);
    },
  },
};
</script>
<style lang="scss" scoped>
.timeWrap {
  margin-top: 10px;
}
</style>
